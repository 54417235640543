import React from "react";
import { Row, Col } from "antd";
import { useParallax } from 'react-scroll-parallax'
import { FormattedMessage } from "react-intl";

export default function AboutUs() {
  const parallax = useParallax({
    easing: "easeOutQuad",
    opacity: [0, 2]
  });
  return (
    <>
      <section id="aboutUs" style={{ paddingTop: "3%", paddingBottom: '8%' }}>
        <Row justify="space-around" align="middle" className="row-content" ref={parallax.ref} gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24}>
            <h1 className="h1-evBike"><FormattedMessage
              id="ABOUT_US"
              defaultMessage="ABOUT US"
              values={{
                code: (word) => <strong>{word}</strong>,
              }}
            /></h1>

          </Col>

          <Col xs={24} sm={24} md={24} className="text-card-meta">
            <FormattedMessage
              id="ABOUT_US_TEXT1"
              defaultMessage=""
              values={{
                code: (word) => <strong>{word}</strong>,
              }}
            />

          </Col>

          <Col xs={24} sm={24} md={24} className="text-card-meta">
            <FormattedMessage
              id="ABOUT_US_TEXT2"
              defaultMessage=""
              values={{
                code: (word) => <strong>{word}</strong>,
              }}
            />
          </Col>

          <Col xs={24} sm={24} md={24} className="text-card-meta">
            <FormattedMessage
              id="ABOUT_US_TEXT3"
              defaultMessage=""
              values={{
                code: (word) => <strong>{word}</strong>,
              }}
            />
          </Col>

          <Col xs={24} sm={24} md={24} className="text-evBike">
            E-Bike UP
          </Col>

          <Col xs={24} sm={24} md={24} className="text-evBike">
            <FormattedMessage
              id="ABOUT_US_TEXT4"
              defaultMessage=""
              values={{
                code: (word) => <strong>{word}</strong>,
              }}
            />
          </Col>

          <Col xs={24} sm={24} md={24} className="text-card-meta">
            <FormattedMessage
              id="ABOUT_US_TEXT5"
              defaultMessage=""
              values={{
                code: (word) => <strong>{word}</strong>,
              }}
            />
          </Col>

          <Col xs={24} sm={24} md={24} className="text-evBike" style={{ fontWeight: "bold" }}>
            <FormattedMessage
              id="ABOUT_US_TEXT6"
              defaultMessage=""
              values={{
                code: (word) => <strong>{word}</strong>,
              }}
            />
          </Col>

          <Col xs={24} sm={24} md={24} className="text-card-meta">
            <FormattedMessage
              id="ABOUT_US_TEXT7"
              defaultMessage=""
              values={{
                code: (word) => <strong>{word}</strong>,
              }}
            />
          </Col>

          <Col xs={24} sm={24} md={24} className="text-evBike" style={{ fontWeight: "bold" }}>
            <FormattedMessage
              id="ABOUT_US_TEXT8"
              defaultMessage=""
              values={{
                code: (word) => <strong>{word}</strong>,
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={24} className="text-card-meta">
            <FormattedMessage
              id="ABOUT_US_TEXT9"
              defaultMessage=""
              values={{
                code: (word) => <strong>{word}</strong>,
              }}
            />
            </Col>
        </Row>
      </section>
    </>
  );
}

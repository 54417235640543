import React from "react";
import "./App.css";

import Standard from "./layout/Standard";
import AboutUs from "./layout/AboutUs";
import News from "./layout/News";
import { Routes, Route } from "react-router-dom"

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={ <Standard/> } />
        <Route path="website" element={ <Standard/> } />
        <Route path="aboutUs" element={ <AboutUs/> } />
        <Route path="news" element={ <News/> } />
      </Routes> 

    </>
  );
}

export default App;

import React from 'react'
import { Row, Col } from "antd";
import { FormattedMessage } from "react-intl";
import { backInOut } from 'framer-motion';

export default function ContactUs() {
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <div id="contactUs"
        style={{ backgroundColor: "#0277C1", paddingBottom: 80, paddingTop: 80, }}>
        <div style={{ color: "#fff" }}>
          <Row gutter={[32, 32]} justify="center" align="middle">
            <Col xs={24} sm={24} md={6} style={{ textAlign: 'center' }}>
              <img src="./image/Icon WEB-10.png" height={"170px"}></img>
              <div style={{marginTop: -55,fontSize:18,fontWeight:'bold'}}>
                <FormattedMessage
                  id="FOLLOW_US"
                  defaultMessage=""
                  values={{
                    code: (word) => <strong>{word}</strong>,
                  }}
                />
              </div>
              <div style={{ marginTop: 5 }}>
                <img src="./image/Icon WEB-02.png" height={"25px"} style={{ marginRight: 8, cursor: 'pointer' }} onClick={() => openInNewTab("https://www.facebook.com/profile.php?id=100093523961216")}></img>
                <img src="./image/Icon WEB-03.png" height={"25px"} style={{ marginRight: 8, cursor: 'pointer' }} onClick={() => openInNewTab("https://www.youtube.com/channel/UCmhFPALQJb11_kHF4AStNEQ")}></img>
              </div>

            </Col>
            <Col xs={24} sm={18}>
              <Row className='textContact' >
                <Col xs={24} sm={8} style={{ marginBottom: 8 }}>
                  <div className='font-KanitSemiBold' style={{ fontSize: 18 }}>
                    <FormattedMessage
                      id="CONTACT_US"
                      defaultMessage=""
                      values={{
                        code: (word) => <strong>{word}</strong>,
                      }}
                    />

                  </div>
                  <div className='font-KanitSemiBold' style={{ fontSize: 18 }}>
                    <FormattedMessage
                      id="OFFICE1"
                      defaultMessage=""
                      values={{
                        code: (word) => <strong>{word}</strong>,
                      }}
                    />
                  </div>
                  <div className='font-KanitRegular'>
                    <FormattedMessage
                      id="OFFICE1_ADDR"
                      defaultMessage=""
                      values={{
                        code: (word) => <strong>{word}</strong>,
                      }}
                    />
                  </div>
                  <div className='font-KanitRegular'>
                    <FormattedMessage
                      id="OFFICE1_ADDR2"
                      defaultMessage=""
                      values={{
                        code: (word) => <strong>{word}</strong>,
                      }}
                    />
                  </div>
                  <div className='font-KanitRegular'>
                    <FormattedMessage
                      id="OFFICE1_ADDR3"
                      defaultMessage=""
                      values={{
                        code: (word) => <strong>{word}</strong>,
                      }}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={8} style={{ marginBottom: 8 }}>
                  <div className='font-KanitSemiBold' style={{ fontSize: 18 }}>
                    <FormattedMessage
                      id="OFFICE2"
                      defaultMessage=""
                      values={{
                        code: (word) => <strong>{word}</strong>,
                      }}
                    />
                  </div>
                  <div className='font-KanitRegular'>
                    <FormattedMessage
                      id="OFFICE2_ADDR"
                      defaultMessage=""
                      values={{
                        code: (word) => <strong>{word}</strong>,
                      }}
                    />
                  </div>
                  <div className='font-KanitRegular'>
                    <FormattedMessage
                      id="OFFICE2_ADDR2"
                      defaultMessage=""
                      values={{
                        code: (word) => <strong>{word}</strong>,
                      }}
                    />
                  </div>
                  <div className='font-KanitRegular'>
                    <FormattedMessage
                      id="OFFICE2_ADDR3"
                      defaultMessage=""
                      values={{
                        code: (word) => <strong>{word}</strong>,
                      }}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={8} style={{ marginBottom: 8 }}>
                  <div className='font-KanitSemiBold' style={{ fontSize: 18 }}> <FormattedMessage
                    id="CONTACT_NUMBER"
                    defaultMessage=""
                    values={{
                      code: (word) => <strong>{word}</strong>,
                    }}
                  /></div>

                  <div className='font-KanitRegular'><img src="./image/Icon WEB-06.png" height={"18px"}></img> <FormattedMessage
                    id="TELEPHONE"
                    defaultMessage=""
                    values={{
                      code: (word) => <strong>{word}</strong>,
                    }}
                  /></div>
                  <div className='font-KanitRegular'><img src="./image/Icon WEB-08.png" height={"18px"}></img> <FormattedMessage
                    id="EMAIL_ADD"
                    defaultMessage=""
                    values={{
                      code: (word) => <strong>{word}</strong>,
                    }}
                  /></div>
                </Col>
              </Row>
            </Col>
          </Row>

        </div>
      </div>
    </>
  )
}

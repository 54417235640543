import React ,{useContext} from 'react'
import { Button } from "antd";
import { useParallax } from 'react-scroll-parallax'
import { FormattedMessage } from "react-intl";
import { Context } from "../Wrapper";

export default function Rental() {
  const context = useContext(Context);
  // const parallax = useParallax({
  //   easing: "easeOutQuad",
  //   translateX: [50, -5],
  // });

  const parallax = useParallax({
    easing: "easeOutQuad",
    opacity: [0, 2]
  });

  return (
    <>
      <section id="rental">
        <div className='container-rental' ref={parallax.ref} style={{ marginBottom: "8%" }}>
          <h1 style={{ textAlign: "center" }} className="h1-evBike">RENTAL</h1>
          <img
            src="./image/Rental copy.jpg"
            className='image_rental'
          ></img>
          <a href={context.locale === "th-th" ? "https://www.asmacharge.com/rental?lang=th" : "https://www.asmacharge.com/rental?lang=en"} >
            <Button className="btn">
              <FormattedMessage
                id="DETAILS"
                defaultMessage="details"
                values={{
                  code: (word) => <strong>{word}</strong>,
                }}
              />
            </Button>
          </a>
        </div>
      </section>
    </>
  )
}

import React, { useState, useContext } from "react";
import { Row, Col } from "antd";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import HoverVideoPlayer from 'react-hover-video-player';
import { Context } from "../Wrapper";

export default function Home() {
  const context = useContext(Context);
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  const boxVariant = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const videos = [
    { id: 1, image: "./image/video1.png", video: "./image/video1.mp4", title: "เที่ยวสมุยใน1วัน", titleEn: "Travel to Samui in 1 day" },
    { id: 2, image: "./image/video2.png", video: "./image/video2.mp4", title: "สอนการใช้งานเบื้องต้น", titleEn: "Teach basic use" },
    { id: 3, image: "./image/video3.png", video: "./image/video3.mp4", title: "ท่องเที่ยวสมุยร่วมกับ ททท.", titleEn: "Thailand is amazing with TAT." },
  ]

  const [hover, setHover] = useState(null)
  return (
    <>
      <section id="home" className="home-container" >
        <img
          src="./image/AS MaCharge Travel 3 copy.jpg"
          width={"100%"}
          // height={"100vh"}
          style={{ width: "100%" }}
        ></img>

        <div>
          <Row>
            <Col xs={0} sm={24} md={24}>
              <div className="aboutUs-text">
                <FormattedMessage
                  id="DESCRIPTION"
                  defaultMessage=""
                  values={{
                    code: (word) => <strong>{word}</strong>,
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>

        <div>
          <Row gutter={[24, 24]} style={{ marginTop: 16, width: "85vw" }}>
            {
              videos.map((item, index) =>
                <Col xs={24} md={8}>
                  <div>
                    <HoverVideoPlayer
                      videoClassName={hover === index ? "video-home" : ""}
                      videoSrc={item?.video}
                      loop={true}
                      controls={true}
                      pausedOverlay={
                        <img
                          src={item?.image}
                          alt=""
                          style={{
                            // Make the image expand to cover the video's dimensions
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: 8,
                            minHeight: 270,
                          }}
                        />
                      }
                      loadingStateTimeout={1000}
                      loadingOverlay={
                        <div className="loading-overlay">
                          <div className="loading-spinner" />
                        </div>
                      }
                      onHoverStart={() => setHover(index)}
                      onHoverEnd={() => setHover(null)}
                      focused={hover === index}
                      volume={0.5}
                      muted={false}
                      controlsList="nodownload"
                    />
                  </div>

                  <div className="text-evBike">
                    {context.locale === "th-th" ? item.title : item.titleEn}
                  </div>
                </Col>


              )
            }


          </Row>
        </div>
      </section >
    </>
  );
}

import React from 'react'
import { Menu, Select, Button, Row, Col, Drawer } from "antd";
import { GB, TH } from "country-flag-icons/react/3x2";
import { FormattedMessage } from "react-intl";
import { Context } from "../Wrapper";
import { Link } from "react-router-dom";
import { MenuOutlined } from '@ant-design/icons';

export default function MenuMobile({handleClickScroll,context}) {
  const styleFlagIcon = {
    width: "30px",
    height: "30px",
    marginRight: "5px",
  };

  const handleMenuClick = (e) => {
    if(e.key === "2"){
      context.selectLanguage("th-th")
    }else{
      context.selectLanguage("en-gb")
    }
  };

  const items = [
    {
      label: 'EN',
      key: '1',
      icon: <GB style={styleFlagIcon} />,
    },
    {
      label: 'TH',
      key: '2',
      icon: <TH style={styleFlagIcon} />,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  
  return (
    <Menu className='menuMobile'
  >
    <Menu.Item key="booking">
    <a href={context.locale === "th-th" ? "https://www.asmacharge.com/rental?lang=th" : "https://www.asmacharge.com/rental?lang=en"}>
        <FormattedMessage
          id="BOOKING"
          defaultMessage="BOOKING"
          values={{
            code: (word) => <strong>{word}</strong>,
          }}
        />
      </a>
    </Menu.Item>
    <Menu.Item key="aboutUs">
      <Link to="/aboutUs" onClick={() => handleClickScroll("aboutUs")} className='menuMobile'>
        <FormattedMessage
          id="ABOUT_US"
          defaultMessage="ABOUT US"
          values={{
            code: (word) => <strong>{word}</strong>,
          }}
        />
      </Link>
    </Menu.Item>
    <Menu.Item key="evBike">
      <Link to="/#evBike" onClick={() => handleClickScroll("evBike")} className='menuMobile'>
        <FormattedMessage
          id="EV_BIKE"
          defaultMessage="EV BIKE"
          values={{
            code: (word) => <strong>{word}</strong>,
          }}
        />
      </Link>
    </Menu.Item>
    <Menu.Item key="swappingStation">
      <Link to="/website#swappingStation" onClick={() => handleClickScroll("swappingStation")} className='menuMobile'>
        <FormattedMessage
          id="SWAPPING_STATION"
          defaultMessage="SWAPPING STATION"
          values={{
            code: (word) => <strong>{word}</strong>,
          }}
        />
      </Link>
    </Menu.Item>
    <Menu.Item key="website">
      <Link to="/#website" onClick={() => handleClickScroll("website")} className='menuMobile'>
        <FormattedMessage
          id="WEBSITE"
          defaultMessage="WEBSITE"
          values={{
            code: (word) => <strong>{word}</strong>,
          }}
        />
      </Link>
    </Menu.Item>
    <Menu.Item key="promotion">
      <Link to="/#promotion" onClick={() => handleClickScroll("promotion")} className='menuMobile'>
        <FormattedMessage
          id="PROMOTION"
          defaultMessage="PROMOTION"
          values={{
            code: (word) => <strong>{word}</strong>,
          }}
        />
      </Link>
    </Menu.Item>
    <Menu.Item key="contactUs">
      <Link to="/#contactUs" onClick={() => handleClickScroll("contactUs")} className='menuMobile'>
        <FormattedMessage
          id="CONTACT_US"
          defaultMessage="CONTACT US"
          values={{
            code: (word) => <strong>{word}</strong>,
          }}
        />
      </Link>
    </Menu.Item>
    <Menu.Item key="signup-signing">
    <a href={context.locale === "th-th" ? "https://www.asmacharge.com/rental/member/login?lang=th" : "https://www.asmacharge.com/rental/member/login?lang=en"}>
        <FormattedMessage
          id="SIGNUP-SIGNIN"
          defaultMessage="SIGN UP/SIGN IN"
          values={{
            code: (word) => <strong>{word}</strong>,
          }}
        />
      </a>
    </Menu.Item>
    <Menu.Item key="select-lang" className='select-lang'>
      {/* <Button type={context.locale === "th-th" ? "primary" : "default"} shape="circle" className='ant-btn-lang' onClick={() => context.selectLanguage("th-th")}>
        ไทย
      </Button>
      <Button type={context.locale === "en-gb" ? "primary" : "default"} shape="circle" className='ant-btn-lang' onClick={() => context.selectLanguage("en-gb")}>
        EN
      </Button> */}
      <TH style={styleFlagIcon} onClick={() => context.selectLanguage("th-th")} />
       <GB style={styleFlagIcon} onClick={() => context.selectLanguage("en-gb")} />
    </Menu.Item>

  </Menu>
  )
}

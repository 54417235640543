import React, { useContext } from 'react'
import { Row, Col, Button } from "antd";
import { useParallax } from 'react-scroll-parallax'
import { FormattedMessage } from "react-intl";
import { Context } from "../Wrapper";

export default function Website() {
  const context = useContext(Context);

  const parallax_left = useParallax({
    easing: "easeOutQuad",
    translateX: [-50, 10],
  });

  const parallax_right = useParallax({
    easing: "easeOutQuad",
    translateX: [50, -20],
  });

  return (
    <>
      <section id="website" className='bg2' style={{ padding: "6% 0" }}>
        <Row justify="space-around" align="middle" className="row-content">
          <Col xs={24} sm={24} md={12} className="container-bike">
            <div ref={parallax_left.ref}>
              <h1 className="h1-evBike">
                <FormattedMessage
                  id="WEBSITE"
                  defaultMessage="WEBSITE"
                  values={{
                    code: (word) => <strong>{word}</strong>,
                  }}
                />
              </h1>
              <div className="text-evBike">
                <FormattedMessage
                  id="DESCRIPTION_WEBSITE1"
                  defaultMessage="A website for facilitating users to rent electric motorcycles, E-BikeUP"
                  values={{
                    code: (word) => <strong>{word}</strong>,
                  }}
                />
              </div>
              <div className="text-evBike">
                <FormattedMessage
                  id="DESCRIPTION_WEBSITE2"
                  defaultMessage="that can display battery information within the station"
                  values={{
                    code: (word) => <strong>{word}</strong>,
                  }}
                />
              </div>
              <div className="text-evBike">
                <FormattedMessage
                  id="DESCRIPTION_WEBSITE3"
                  defaultMessage="and search for important places conveniently As well as helping"
                  values={{
                    code: (word) => <strong>{word}</strong>,
                  }}
                />
              </div>
              <div className="text-evBike">
                <FormattedMessage
                  id="DESCRIPTION_WEBSITE4"
                  defaultMessage=" "
                  values={{
                    code: (word) => <strong>{word}</strong>,
                  }}
                />
              </div>
              <a href={context.locale === "th-th" ? "https://samui.macharge.co?lang=th" : "https://samui.macharge.co?lang=en"}>
                <Button className="btn">
                  <FormattedMessage
                    id="DETAILS"
                    defaultMessage="details"
                    values={{
                      code: (word) => <strong>{word}</strong>,
                    }}
                  />
                </Button>
              </a>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <div ref={parallax_right.ref}>
              <img src="./image/MaCharge Mobile Mock Up copy.png" width={"100%"}></img>
            </div>
          </Col>
        </Row>
      </section>


    </>
  )
}

import React, { useContext, useState, useEffect } from 'react'
import { Menu, Select, Button, Row, Col, Drawer } from "antd";
import { GB, TH } from "country-flag-icons/react/3x2";
import { FormattedMessage } from "react-intl";
import { Context } from "../Wrapper";
import { Link } from "react-router-dom";
import { MenuOutlined } from '@ant-design/icons';
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';

const { Option } = Select;

export default function NavBar() {
  const context = useContext(Context);

  const styleFlagIcon = {
    width: "17px",
    height: "15px",
    marginRight: "5px",
  };

  const handleClickScroll = (name) => {
    setTimeout(() => {
      const element = document.getElementById(name);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 200);
  };

  useEffect(() => {
    const hash = window.location.hash;
    if(hash){
      handleClickScroll(hash.replace("#",""));
    }
  }, []);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 1038

  return (
    <>
      {
        !isMobile ? <MenuDesktop handleClickScroll={handleClickScroll} context={context}/> :
          <Row className="navbar" style={{background:"#ffffff"}}>
            <Col xs={4}>
              <Link to="/#home" onClick={() => handleClickScroll("home")}>
                <img src="./image/Icon WEB-09.png" className="logo" />
              </Link>
            </Col>
            <Col xs={20} style={{ padding: "20px 12px 0 0", textAlign: "end" }}>
              <Button type="primary" onClick={showDrawer} icon={<MenuOutlined />} style={{ background: "#0277c1" }} />
            </Col>

          </Row>
      }
      <Drawer title="" placement="right" onClose={onClose} open={open} onClick={onClose}>
        <MenuMobile handleClickScroll={handleClickScroll} context={context}/>
      </Drawer>
    </>
  )
}

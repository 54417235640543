import React ,{useContext} from "react";
import { useParallax } from 'react-scroll-parallax'
import { Context } from "../Wrapper";

export default function Map() {
  const context = useContext(Context);

  const parallax = useParallax({
    easing: "easeOutQuad",
    // opacity :[0,2],
    scale:[0, 0.9],
  });
  // const parallax_right = useParallax({
  //   easing: "easeOutQuad",
  //   translateX: [80, -30],
  // });
  return (
    <>
      <section id="map">
        <div ref={parallax.ref} style={{textAlign:"center"}}>
        <h1 style={{ textAlign: "center"}} className="h1-evBike">Map</h1>
        {context.locale === "th-th" ?  <img src="./image/Map-01.png" width={"100%"} style={{maxWidth:900}}></img> : <img src="./image/Map-05.png" width={"100%"} style={{maxWidth:900}}></img>
        }
       
          {/* <iframe
            src="https://samui.macharge.co/"
            height="665px"
            width="90vw"
            frameBorder="0"
            scrolling="no"
            style={{ width: "90vw" }}
          ></iframe> */}
        </div>
      </section>
    </>
  );
}

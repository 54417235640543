import React from "react";
import { Button, Row, Col } from "antd";
import { useParallax } from "react-scroll-parallax";
import { FormattedMessage } from "react-intl";

export default function EvBike() {
  const parallax_left = useParallax({
    easing: "easeOutQuad",
    translateX: [-80, 30],
  });

  const parallax_right = useParallax({
    easing: "easeOutQuad",
    translateX: [80, -30],
  });

  return (
    <>
      <section id="evBike" style={{padding:"6% 0"}}>
        <Row justify="space-around" align="middle" className="row-content">
          <Col xs={24} sm={24} md={12}>
            <div ref={parallax_left.ref}>

              <h1 className="h1-evBike">
              <FormattedMessage
                  id="TITLE_EV_BIKE"
                  defaultMessage="EV Bike"
                  values={{
                    code: (word) => <strong>{word}</strong>,
                  }}
                />
              </h1>

              <div className="text-evBike">
                <FormattedMessage
                  id="DESCRIPTION_EV_BIKE1"
                  defaultMessage="electric motorcycle Driven by electrical energy (Electric Vehicles:EV)"
                  values={{
                    code: (word) => <strong>{word}</strong>,
                  }}
                />
              </div>

              <div className="text-evBike">
                <FormattedMessage
                  id="DESCRIPTION_EV_BIKE2"
                  defaultMessage="hat is modern, meets excellent performance standards and is environmentally friendly"
                  values={{
                    code: (word) => <strong>{word}</strong>,
                  }}
                />
              </div>

              {/* <Button className="btn">
                <FormattedMessage
                  id="DETAILS"
                  defaultMessage="details"
                  values={{
                    code: (word) => <strong>{word}</strong>,
                  }}
                />
              </Button> */}
            </div>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <div ref={parallax_right.ref}>
              <img
                src="./image/IMG_9347 copy Flip copy.png"
                width={"100%"}
                style={{ margin: "0 50px 0 50px" }}
              ></img>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
}

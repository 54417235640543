import React, { useContext, useState } from 'react'
import { Row, Col, Card, Modal } from "antd";
import { useParallax } from 'react-scroll-parallax'
import { FormattedMessage } from "react-intl";
import { Context } from "../Wrapper";

const { Meta } = Card;

export default function Promotion() {
  const context = useContext(Context);

  const [item, setItem] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false);

  const parallax = useParallax({
    easing: "easeOutQuad",
    opacity: [0, 2]
  });

  const selectItem = (data) => {
    setItem(data);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // var data = [{ id: 1, title: "แพ็กเกจรายวัน", title_en: "Daily package", description: "ใหม่! Wonderful Pack คุ้มสุด ดีสุด พร้อมรับสิทธิพิเศษตอบทุกไลฟ์สไตล์ทุกวัน",description_en:"New! Wonderful Pack, the best value, the best, ready to receive special privileges to answer every lifestyle every day." },
  // { id: 2, title: "แพ็กเกจรายวัน", title_en: "Daily package", description: "ใหม่! Wonderful Pack คุ้มสุด ดีสุด พร้อมรับสิทธิพิเศษตอบทุกไลฟ์สไตล์ทุกวัน",description_en:"New! Wonderful Pack, the best value, the best, ready to receive special privileges to answer every lifestyle every day." },
  // { id: 3, title: "แพ็กเกจรายวัน", title_en: "Daily package", description: "ใหม่! Wonderful Pack คุ้มสุด ดีสุด พร้อมรับสิทธิพิเศษตอบทุกไลฟ์สไตล์ทุกวัน",description_en:"New! Wonderful Pack, the best value, the best, ready to receive special privileges to answer every lifestyle every day." },
  // { id: 4, title: "แพ็กเกจรายวัน", title_en: "Daily package", description: "ใหม่! Wonderful Pack คุ้มสุด ดีสุด พร้อมรับสิทธิพิเศษตอบทุกไลฟ์สไตล์ทุกวัน",description_en:"New! Wonderful Pack, the best value, the best, ready to receive special privileges to answer every lifestyle every day." },
  // { id: 5, title: "แพ็กเกจรายวัน", title_en: "Daily package", description: "ใหม่! Wonderful Pack คุ้มสุด ดีสุด พร้อมรับสิทธิพิเศษตอบทุกไลฟ์สไตล์ทุกวัน",description_en:"New! Wonderful Pack, the best value, the best, ready to receive special privileges to answer every lifestyle every day." },
  // { id: 6, title: "แพ็กเกจรายวัน", title_en: "Daily package", description: "ใหม่! Wonderful Pack คุ้มสุด ดีสุด พร้อมรับสิทธิพิเศษตอบทุกไลฟ์สไตล์ทุกวัน",description_en:"New! Wonderful Pack, the best value, the best, ready to receive special privileges to answer every lifestyle every day." }
  // ];

  var data = [
    // {
    //   id: 1,
    //   image: "./image/Nov-Pro-2.jpg",
    //   imageEn: "./image/ENG-Nov-Pro-2.jpg",
    //   title_th: "โปรเด็ดประจำเดือน",
    //   description_th: "โปรโมชันพิเศษประจำเดือน เช่ารถกับ E-Bike ราคาวันละ 199 บาท หรือเช่าแบบรายอาทิตย์ที่ราคา 1,050 บาท พร้อมสลับแบตเตอรี่ไม่อั้น",
    //   title_en: "Monthly special promotions",
    //   description_en: "Monthly special promotions, rent a car with E-Bike for 199 baht per day or rent weekly for 1,050 baht with unlimited battery swaps."
    // },

    // {
    //   id: 2,
    //   image: "./image/Nov-Pro-mountly.jpg",
    //   imageEn: "./image/ENG-Pro-mountly.jpg",
    //   title_th: "เช่ารายเดือน ",
    //   description_th: "ตามคำเรียกร้องกับการเช่ารถรายเดือน ทางE-Bike up  จึงจัดการเช่ารถราคาพิเศษกับลูกค้าที่เช่ารายเดือน โดยพิเศษตอนรับการเช่ารายเดือนใหม่ เริ่มต้นที่เดือนละ 4,500 บาท พร้อมสลับแบตเตอรี่ไม่อั้น ",
    //   title_en: "Monthly rental",
    //   description_en: "According to the demand for monthly car rentals, E-Bike up has arranged special car rental prices for customers who rent monthly. Especially when accepting a new monthly rental. Starting at 4,500 baht per month with unlimited battery swaps."
    // },

    // {
    //   id: 3,
    //   image: "./image/Oct-Pro-Cash100.jpg",
    //   imageEn: "./image/ENG-Nov-Pro-Cash100ENG.jpg",
    //   title_th: "ททท. ส่งเสริมการท่องเที่ยว ร่วมกับ E-Bike Up ",
    //   description_th: "สุดพิเศษกับ บัตรกำนัลเงินสด มูลค่า 100 บาท สามารถนำมาใช้ที่ ร้าน E-Bike Up ได้จนถึง 31 ธันวาคม 2566 กติกาการแจกติดตามได้ที่เพจ ททท. อย่าลืมมาใช้กันเยอะๆ นะ",
    //   title_en: "TAT promotes tourism in collaboration with E-Bike Up.",
    //   description_en: "Very special with a cash voucher worth 100 baht that can be used at the E-Bike Up shop until 31 December 2023. Distribution rules can be followed at the TAT page. Don't forget to use it a lot."
    // }
  ];

  return (
    <>
      <section id="promotion">
        <div ref={parallax.ref}>
          <h1 style={{ textAlign: "center" }} className="h1-evBike">PROMOTION</h1>
          <Row gutter={[16, 16]} className="row-content" justify="center">
            {
              
              <FormattedMessage
                        id="NO_PROMOTIONS"
                        defaultMessage="There are no promotions at this time."
                        values={{
                          code: (word) => <strong>{word}</strong>,
                        }}
                      />
            }
            {
              data.map(item =>
                <Col sm={24} md={24} lg={8} key={item.id}>
                  <Card cover={<img alt="example" src={context.locale === "th-th" ? item.image : item.imageEn} />}>
                    <Meta
                      title={<div className='text-card-meta-title'>{context.locale === "th-th" ? item.title_th : item.title_en}</div>}
                      description={
                      <div className='text-card-meta'>{context.locale === "th-th" ? 
                      (item.description_th?.length > 90 ? item.description_th?.slice(0, 90) + "..." : item.description_th) :
                      (item.description_en?.length > 90 ? item.description_en?.slice(0, 90) + "..." : item.description_en) 
                      }</div>}
                    />
                    <div className='more-card' onClick={() => selectItem(item)}>
                      <FormattedMessage
                        id="SEE_MORE"
                        defaultMessage="See more"
                        values={{
                          code: (word) => <strong>{word}</strong>,
                        }}
                      /> {">"}
                    </div>
                  </Card>
                </Col>
              )
            }
          </Row>
        </div>

        <Modal title={<div className='text-card-meta-title'>{context.locale === "th-th" ? item?.title_th : item?.title_en}</div>} footer={[]} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <Card cover={<img alt="example" src={context.locale === "th-th" ? item?.image : item?.imageEn} />}>
            <Meta
              title={<div className='text-card-meta-title'>{context.locale === "th-th" ? item?.title_th : item?.title_en}</div>}
              description={<div className='text-card-meta'>{context.locale === "th-th" ? item?.description_th : item?.description_en}</div>}
            />
          </Card>
        </Modal>

      </section>
    </>
  )
}
